import { Helmet } from "react-helmet-async";
import { AnimatedButton } from "../components/shared/button";
import { useId } from "react";
import Bitcoin1 from "../images/bitcoin-1.png";
import Bitcoin2 from "../images/bitcoin-2.png";
import Bitcoin3 from "../images/bitcoin-3.png";
import Bitcoin4 from "../images/bitcoin-4.png";
import Heremes from "../images/hermes_new.png";
import PumpCalculator from "../images/pump_calculator_new.png";
import OGPump from "../images/og_pump_new.png";
import Chronos from "../images/chronos_new.png";
import Icarus from "../images/icarus_new.png";
import { motion } from "framer-motion";
import { IoIosInformationCircleOutline } from "react-icons/io";

import { Transition } from "../animation/transition";
import Star from "../images/star-img.svg";
import { ExpandableCardDemo } from "../components/ui/toolsDetails";
import { useState } from "react";

const Tools = () => {
  const [active, setActive] = useState(null);
  const id = useId();

  const cards = [
    {
      id: 1,
      title: "Heremes",
      subtitle: "Mass Sniper",
      description:
        "Buy tokens and split across many wallets. Multiply holders in an instant.",
      demoLink: "https://www.youtube.com/watch?v=PKWbbbhznq8",
      accessLink: "https://discord.gg/8W8TdfKXMT",
      image: Heremes,
      showDemo: true,
      tags: ["desktop-app", "auto-bot", "paid"],
    },
    // {
    //   id: 2,
    //   title: "Pump Calculator",
    //   subtitle: "Trade Calculator",
    //   demoLink: "https://www.youtube.com/watch?v=UUDlttL6kDY",
    //   description:
    //     "Stop blowing your port. Find out how much you actually pay in fees. Trade smart.",
    //   accessLink: "https://pumpcalculator.fun",
    //   image: PumpCalculator,
    //   showDemo: true,
    // },
    {
      id: 3,
      title: "OG Pump",
      subtitle: "Find OG Coins",
      demoLink: "https://www.youtube.com/watch?v=ChOLyTDs148",
      description:
        "This tool helps you find OG coins. Analyse tokens quickly. Verify Authenticity.",
      accessLink: "https://ogpump.fun",
      image: OGPump,
      showDemo: true,
    },
    {
      id: 4,
      title: "Chronos",
      subtitle: "Dex Paid Scanner",
      description:
        "Quickly find out if and when a projet pays for dexscreener profile.",
      demoLink: "https://www.youtube.com/watch?v=RZ-Qi81Ieyo",
      accessLink: "https://discord.gg/8W8TdfKXMT",
      image: Chronos,
      showDemo: true,
      tags: ["chrome-extension", "signals", "free"],
    },
    // {
    //   id: 5,
    //   title: "Icarus",
    //   subtitle: "Token Launcher",
    //   description:
    //     "Launch tokens at lighting speed. With volume & comment bots. + Hidden Features.",
    //   accessLink: "#",
    //   image: Icarus,
    //   showDemo: false,
    //   comingSoon: true,
    // },
  ];

  return (
    <>
      <Helmet>
        <title>Crypto Whale Tracking Tools & Analytics | Whalesnipr</title>
        <meta
          name="description"
          content="Access powerful crypto tracking tools, including real-time alerts, historical analysis, wallet tracking, and market movement predictions."
        />

        {/* Keywords */}
        <meta
          name="keywords"
          content="crypto tracking tools, whale analytics, blockchain monitoring, crypto wallet tracker, market movement analysis, ai agents, solana, wins"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Crypto Whale Tracking Tools & Analytics | Whalesnipr"
        />
        <meta
          property="og:description"
          content="Access powerful crypto whale tracking tools, including real-time alerts, historical analysis, wallet tracking, and market movement predictions."
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo.svg" />

        {/* Twitter */}
        <meta
          name="twitter:title"
          content="Crypto Tracking Tools & Analytics | Whalesnipr"
        />
        <meta
          name="twitter:description"
          content="Access powerful crypto whale tracking tools, including real-time alerts, historical analysis, wallet tracking, and market movement predictions."
        />

        {/* Canonical URL */}
        <link rel="canonical" href="https://www.whalesnipr.com/tools" />
      </Helmet>

      <section className="md:mt-[15rem] z-30 mb-20 mt-[10rem]">
        <div className="absolute md:top-40 md:left-20 top-[6rem] left-4 animate-flip  delay-100">
          <img
            src={Bitcoin1}
            className="h-10 w-10 md:w-full md:h-full"
            alt="crypto coin"
          />
        </div>
        <div className="absolute md:top-80 md:left-10 top-[25rem] left-0  animate-flip  delay-200">
          <img
            className="h-14 w-14 md:w-full md:h-full"
            src={Bitcoin2}
            alt="crypto coin"
          />
        </div>
        <div className="absolute md:top-72 top-[6rem] md:right-40 right-2 animate-flip delay-300">
          <img
            className="h-12 w-12 md:w-full md:h-full"
            src={Bitcoin3}
            alt="crypto coin"
          />
        </div>
        <div className="absolute md:top-32 md:right-28  right-16 top-20 animate-flip delay-400">
          <img src={Bitcoin4} alt="crypto coin" />
        </div>

        {/* Stars */}

        <div className="absolute md:top-60 top-24 md:right-28 right-0">
          <img
            className="md:w-[30px] md:h-[30px] h-[20px] w-[20px]"
            src={Star}
            alt="star"
          />
        </div>
        <div className="absolute md:top-60 top-[8rem] md:left-40 left-[55px]">
          <img
            className="md:w-[45px] md:h-[45px] h-[20px] w-[20px]"
            src={Star}
            alt="star"
          />
        </div>
        <div className="absolute md:top-32 top-10 md:right-60 right-20">
          <img
            className="md:w-[45px] md:h-[45px] h-[30px] w-[30px]"
            src={Star}
            alt="star"
          />
        </div>
        <div className="xl:px-[9.5rem]  relative md:px-[3rem] p-0">
          <h2 className="text-center text-white  md:text-[2.3rem] font-normal mb-10">
            Tools
          </h2>
          <div className="absolute md:top-[40rem] md:left-20 top-[8rem] -left-10 animate-flip  delay-100">
            <img
              src={Bitcoin1}
              className="h-10 w-10 md:w-full md:h-full"
              alt="crypto coin"
            />
          </div>
          <div className="absolute md:-top-10 top-[9rem] md:right-10 -right-10 animate-flip delay-300">
            <img
              className="h-12 w-12 md:w-24 md:h-24"
              src={Bitcoin2}
              alt="crypto coin"
            />
          </div>

          <div className="grid grid-cols-1  font-raleway md:grid-cols-2 xl:grid-cols-3 gap-10">
            {cards.map((card) => (
              <motion.div key={card.id}>
                <Transition>
                  <motion.div
                    layoutId={`card-${card.title}-${id}`}
                    className="relative group flex bg-[#130122]"
                  >
                    <div className="absolute -inset-[3px] bg-gradient-to-r from-[#8F00FF] to-[#FF00D6] opacity-75 group-hover:opacity-100 transition-opacity duration-500"></div>
                    <div className="relative bg-inherit flex-grow">
                      <motion.div layoutId={`image-${card.title}-${id}`}>
                        <img
                          src={card.image}
                          alt={card.title}
                          className="w-full h-[252px] object-cover"
                        />

                        {/* Show small tag indicators in the card view */}
                        {card.tags && card.tags.length > 0 && (
                          <div className="absolute top-4 right-4 flex flex-col gap-2">
                            {card.tags.slice(0, 1).map((tag, index) => (
                              <span
                                key={index}
                                className="px-2 py-1 text-xs rounded-full bg-gradient-to-r from-[#8F00FF] to-[#FF00D6] text-white"
                              >
                                {tag}
                              </span>
                            ))}
                            {card.tags.length > 1 && (
                              <span className="px-2 py-1 text-xs rounded-full bg-black/70 text-white">
                                +{card.tags.length - 1}
                              </span>
                            )}
                          </div>
                        )}
                      </motion.div>
                      <div className="p-4 pb-8 space-y-4">
                        <div className="space-y-2 lg:h-[150px] h-[170px]">
                          <motion.h2
                            layoutId={`title-${card.title}-${id}`}
                            className="text-xl uppercase flex justify-between font-press-start font-bold text-white"
                          >
                            <span>{card.title}</span>
                            <IoIosInformationCircleOutline
                              title="read more"
                              className="cursor-pointer"
                              onClick={() => setActive(card)}
                            />
                          </motion.h2>
                          <motion.h3 className="text-lg font-semibold text-primary-color">
                            {card.subtitle}
                          </motion.h3>
                          <motion.p className="text-gray-300 text-sm">
                            {card.description}{" "}
                          </motion.p>
                        </div>

                        <motion.div
                          layoutId={`button-${card.title}-${id}`}
                          className="flex gap-4 relative bottom-0  cursor-pointer justify-between"
                        >
                          {card.showDemo ? (
                            <a
                              href={card.demoLink}
                              target="_blank"
                              rel="noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <AnimatedButton buttonText={"Watch Demo"} />
                            </a>
                          ) : (
                            ""
                          )}

                          {card.comingSoon ? (
                            <AnimatedButton buttonText={"Coming Soon"} />
                          ) : (
                            <a
                              href={card.accessLink}
                              target="_blank"
                              rel="noreferrer"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <AnimatedButton buttonText={"Get Access"} />
                            </a>
                          )}
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                </Transition>
              </motion.div>
            ))}
          </div>
        </div>
        <ExpandableCardDemo active={active} setActive={setActive} id={id} />
      </section>
    </>
  );
};

export default Tools;
