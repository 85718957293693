import React from "react";
import ContactImage from "../../images/contact-image.png";
import looperGroup from "../../images/LooperGroup.png";
import { AnimatedButton } from "../shared/button";
import { Transition } from "../../animation/transition";

const Contact = () => {
  return (
    <section className="flex mb-24 justify-center flex-col items-center xl:px-[9.5rem] relative  p-0 md:mt-52 mt-24 w-full ">
      <img
        src={ContactImage}
        alt="testimonial background"
        className="z-10 object-cover bottom-6 "
      />

      <img
        src={looperGroup}
        alt="testimonial background"
        className="-z-30 object-cover absolute "
      />
      <div className="absolute bottom-6 z-30 w-full grid place-content-center ">
        <Transition>
          <h1 className="text-center lg:w-[56.8125rem] w-full text-white md:text-[2.3rem]  font-normal mb-2">
            Let’s help you level up in the crypto world
          </h1>
        </Transition>

        <a
          className="mx-auto mt-4"
          href="https://discord.gg/whalesnipr"
          target="_blank"
          rel="noreferrer"
        >
          <AnimatedButton buttonText={"Join Now"} />
        </a>
      </div>
    </section>
  );
};

export default Contact;
